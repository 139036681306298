import React from 'react';

import Layout from '../components/Layout';
import CitiesSection from '../components/CitiesSection';
import TitleSection from '../components/TitleSection';
import HowItWorksSection from '../components/HowItWorksSection';

const Homepage = () => (
  <Layout>
    <TitleSection />
    <HowItWorksSection />
    <CitiesSection />
  </Layout>
);

export default Homepage;
