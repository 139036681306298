import React from 'react';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import brushEffectImg from '../../img/brush-effect.svg';

import style from './TitleSection.module.css';

const TitleSection = () => {
  return (
    <section className={style.container}>
      <div className={style.titlesContainer}>
        <h1 className={style.title}>
          <FormattedHTMLMessage id="main.titleSection.title" />
        </h1>
        <h3 className={style.subTitle}>
          <FormattedHTMLMessage id="main.titleSection.subTitle" />
        </h3>
        <img src={brushEffectImg} className={style.brushEffect} alt="" />
      </div>
    </section>
  );
};

export default TitleSection;
