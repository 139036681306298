import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Headline from '../Headline';
import City from '../City';

import style from './CitiesSection.module.css';

const CitiesSection = ({ data }) => {
  const { allMarkdownRemark } = data;
  const { edges: cities } = allMarkdownRemark;

  return (
    <section className={style.container}>
      <Headline>
        <FormattedHTMLMessage id="main.citiesSection.headline" />
      </Headline>
      <div className={style.list}>
        {cities &&
          cities.map(
            ({
              node: {
                fields: { slug },
                frontmatter: { geoJson, ...otherParams },
              },
            }) => (
              <City
                {...otherParams}
                hoodsCount={geoJson.features.length}
                linkTo={slug}
                key={slug}
              />
            )
          )}
      </div>
    </section>
  );
};

CitiesSection.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default (ownProps) => (
  <StaticQuery
    query={graphql`
      query CitiesSectionQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "city" } } }
          sort: { order: ASC, fields: [frontmatter___title] }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                image {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                countryCode
                geoJson {
                  features {
                    type
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <CitiesSection {...ownProps} data={data} />}
  />
);
