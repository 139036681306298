import React from 'react';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Headline from '../Headline';

import thoughtsIcon from '../../img/icons/thoughts.svg';
import mapSearchIcon from '../../img/icons/map-search.svg';
import harmonyIcon from '../../img/icons/harmony.svg';

import style from './HowItWorksSection.module.css';

const HowItWorksSection = () => (
  <section className={style.container}>
    <Headline className={style.title}>
      <FormattedHTMLMessage id="main.howItWorksSection.headline" />
    </Headline>
    <div className={style.list}>
      <div className={style.item}>
        <img src={mapSearchIcon} alt="read" className={style.itemImage} />
        <h4 className={style.itemTitle}>
          <FormattedHTMLMessage id="main.howItWorksSection.list.read.title" />
        </h4>
        <p>
          <FormattedHTMLMessage id="main.howItWorksSection.list.read.text" />
        </p>
      </div>
      <div className={style.item}>
        <img src={thoughtsIcon} alt="share" className={style.itemImage} />
        <h4 className={style.itemTitle}>
          <FormattedHTMLMessage id="main.howItWorksSection.list.share.title" />
        </h4>
        <p>
          <FormattedHTMLMessage id="main.howItWorksSection.list.share.text" />
        </p>
      </div>
      <div className={style.item}>
        <img src={harmonyIcon} alt="live" className={style.itemImage} />
        <h4 className={style.itemTitle}>
          <FormattedHTMLMessage id="main.howItWorksSection.list.live.title" />
        </h4>
        <p>
          <FormattedHTMLMessage id="main.howItWorksSection.list.live.text" />
        </p>
      </div>
    </div>
  </section>
);

export default HowItWorksSection;
