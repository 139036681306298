import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'gatsby-plugin-intl';

import CityTitle from '../CityTitle';

import pinIcon from '../../img/icons/pin.svg';

import style from './City.module.css';

const City = ({ title, image, hoodsCount, countryCode, linkTo }) => (
  <Link
    to={linkTo}
    className={style.container}
    style={{
      backgroundImage: `url(${
        !!image.childImageSharp ? image.childImageSharp.fluid.src : image
      })`,
    }}
  >
    <div className={style.content}>
      <CityTitle countryCode={countryCode} title={title} />
      <p className={style.description}>
        <FormattedMessage id={`cities.${title}.description`} />
      </p>
      <div className={style.subTitle}>
        <img src={pinIcon} alt="pin" className={style.pinIcon} />
        <FormattedHTMLMessage
          id="main.city.hoodsAmount"
          values={{ hoodsCount }}
        />
      </div>
    </div>
  </Link>
);

City.propTypes = {
  title: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hoodsCount: PropTypes.number.isRequired,
};

export default City;
