import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'gatsby-plugin-intl';

import { getFlagEmoji } from '../../utils/locale-helper';

import style from './CityTitle.module.css';

const CityTitle = ({ title, countryCode, className }) => {
  const classNames = classnames(style.container, className);

  return (
    <div className={classNames}>
      <FormattedMessage id={`cities.${title}.title`} />
      <span className={style.flagIcon}>{getFlagEmoji(countryCode)}</span>
    </div>
  );
};

CityTitle.propTypes = {
  title: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CityTitle;
