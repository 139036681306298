import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TextLink from '../TextLink';

import style from './Headline.module.css';

const Headline = ({ children, linkTo, linkTitleKey, className }) => {
  const classNames = classnames(style.container, className);

  return (
    <div className={classNames}>
      <h2>{children}</h2>
      {linkTo && (
        <TextLink
          className={style.link}
          linkTo={linkTo}
          titleKey={linkTitleKey}
        />
      )}
    </div>
  );
};

Headline.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  linkTo: PropTypes.string,
  linkTitleKey: PropTypes.string,
};

export default Headline;
